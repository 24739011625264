import { fetchJSON } from '@bonnet/next/fetch';

import { DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { vdpDuckPreset } from '@/ducks/presets';

const vdpModelInfoDuck = objectDuckCreator({
    ...vdpDuckPreset,
    store: 'modelInfo',
    initialState: {
        info: {},
        initialLoad: true,
    },
}).extend({
    selectors: () => ({
        getInitialLoad: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).initialLoad),
        getModelInfo: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).info),
    }),
    creators: () => ({
        loadModelInfo: ({ styleId }) => async (dispatch) => {
            const {
                features = {},
                title = '',
            } = await fetchJSON(`/rest/lsc/modelinfo/${styleId}`, {
                circuitBreaker: {
                    id: 'lsc_moduleinfo',
                    timeout: 3000,
                    resetTimeout: 30000,
                    fallback: {},
                },
                headers: {
                    'X-fwd-svc': 'atc',
                },
            });
            await dispatch(vdpModelInfoDuck?.creators?.setKeys({
                info: {
                    features,
                    title,
                },
                initialLoad: false,
            }));
        },
    }),
});

export default vdpModelInfoDuck;
