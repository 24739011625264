import React, { memo } from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import {
    AdSlot,
} from 'reaxl-ads';

const AtcAdSlot = memo(({
    advertisementLabel,
    className,
    isGlobalAdsPlaceholderOff,
    preserveSpace = false,
    showLabel,
    showPlaceholder,
    slotId,
    ...rest
}) => (
    <AdSlot
        advertisementLabel={showLabel ? advertisementLabel : { className: 'hidden' }}
        className={clsx(
            'margin-horizontal-auto',
            className
        )}
        extra={{
            'data-qa-data': JSON.stringify({ ...rest, slotId }),
        }}
        preserveMaxSize={preserveSpace}
        showPlaceholder={showPlaceholder}
        slotId={slotId}
        {...rest}
    />
));

AtcAdSlot.propTypes = {
    advertisementLabel: PropTypes.object,
    className: PropTypes.string,
    extra: PropTypes.object,
    lazyLoading: PropTypes.bool,
    lazyLoadingBottomOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lazyLoadingTopOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    preserveSpace: PropTypes.bool,
    showLabel: PropTypes.bool,
    size: PropTypes.array.isRequired,
    slotId: PropTypes.string.isRequired,
    showPlaceholder: PropTypes.bool,
};

AtcAdSlot.defaultProps = {
    advertisementLabel: {
        className: 'text-right text-size-5',
        text: 'Advertisement',
    },
    //  Using 300 for offsets to mirror LazyRender
    lazyLoadingBottomOffset: '-300',
    lazyLoadingTopOffset: '-300',
    preserveSpace: false,
    showLabel: true,
    showPlaceholder: true,
};

export default AtcAdSlot;
