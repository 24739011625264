import React, { memo, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import _isEqual from 'lodash/isEqual';

import { BrowserStorage } from 'atc-js';

import { sendClick } from 'reaxl-analytics';
import {
    myWalletNonDealNotificationsClick,
    seeDetailsGlobalNavDealClick,
} from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';
import { WalletHeaderPopover } from 'reaxl-wallet';

import { dealsDuck, paymentsDuck } from '@/ducks';

const WalletAlertsContainer = memo(({ enableAlert = false }) => {
    const dispatch = useDispatch();

    const [displayAlert, setDisplayAlert] = useState(enableAlert);

    const walletNotifCache = new BrowserStorage('WALLET_NOTIF_POPOVER', { persist: true });

    const {
        enable_deals_duck: [isDealsDuckEnabled],
    } = useFeatures(['enable_deals_duck']);

    // dealsDuck: code clean up
    const selectorDuck = isDealsDuckEnabled ? dealsDuck : paymentsDuck;
    const dealNotifications = useSelector(selectorDuck?.selectors?.getDealNotifications);
    const dealDetails = useSelector(selectorDuck?.selectors?.getTransformedDealDetails);

    const tradeIn = useSelector(paymentsDuck.selectors.getTradeIn);

    const { hasNewTradeIn = false } = tradeIn;

    const handleWalletAlertClick = useCallback((e, options = {}) => {
        if (options?.isNonDeal) {
            sendClick(myWalletNonDealNotificationsClick, e, options.data);
        } else {
            let location = '';
            // tradeIn only
            if (hasNewTradeIn && (!dealNotifications?.total)) {
                location = 'header-trade-in';
            } else if ((hasNewTradeIn && dealNotifications?.total) || (Object.keys(dealNotifications).length > 2)) {
                location = 'header-multiple-updates'; // ?
            } else if (!hasNewTradeIn && Object.keys(dealNotifications).length) {
                location = 'header-financing';
            }
            sendClick(seeDetailsGlobalNavDealClick, e, { location });
        }
        dispatch(paymentsDuck.creators.updateMyWallet({ displayModal: true, selectedPanelName: e.target.dataset.panel }));
    }, [dealNotifications, dispatch, hasNewTradeIn]);

    // turn off local storage variable so show popover doesn't show
    const resetWalletAlert = () => {
        walletNotifCache.setItem({ ...walletNotifCache.getItem(), show: false });
        setDisplayAlert(false);
    };

    return (
        (displayAlert && (
            <WalletHeaderPopover
                onClick={handleWalletAlertClick}
                hasNewTradeIn={hasNewTradeIn}
                dealDetails={dealDetails}
                dealNotifications={dealNotifications}
                onCloseClick={resetWalletAlert}
                paymentsDuck={paymentsDuck}
                uiContext="alert"
            />
        ))
    );
}, (prevProps, nextProps) => _isEqual(prevProps, nextProps));

export default WalletAlertsContainer;
