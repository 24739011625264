import React from 'react';

import ContentLoader from 'react-content-loader';

import { Panel } from 'reaxl';

function CompareDrawerPlaceholder({
    isCollapsed = false,
    ...rest
}) {
    const contentLoaderProps = {
        speed: 2,
        height: 62,
        primaryColor: '#e1e4e8',
        secondaryColor: '#f8f8f8',
        style: { width: '100%' },
    };

    const compareCollapsedPlaceholder = (
        <ContentLoader
            {...contentLoaderProps}
            height={27}
        >
            <rect
                width={1800}
                height={27}
            />
        </ContentLoader>
    );

    const compareCarouselPlaceholder = (
        <Panel
            className="margin-bottom-4"
            data-cmp="CompareDrawerPlaceholder"
            {...rest}
        >
            <Panel.Body className="padding-vertical-0">
                <div className="col-xs-3">
                    <ContentLoader
                        {...contentLoaderProps}
                    >
                        <rect
                            x={0}
                            y={24}
                            width={144}
                            height={22}
                        />
                    </ContentLoader>
                </div>
                <div className="col-xs-6">
                    <ContentLoader
                        {...contentLoaderProps}
                    >
                        <rect
                            x={0}
                            y={8}
                            width={70}
                            height={54}
                        />
                        <rect
                            x={80}
                            y={8}
                            width={70}
                            height={54}
                        />
                        <rect
                            x={160}
                            y={8}
                            width={70}
                            height={54}
                        />
                        <rect
                            x={240}
                            y={8}
                            width={70}
                            height={54}
                        />
                    </ContentLoader>
                </div>
                <div className="col-xs-3">
                    <ContentLoader
                        {...contentLoaderProps}
                    >
                        <rect
                            x={0}
                            y={24}
                            width={144}
                            height={22}
                        />
                    </ContentLoader>
                </div>
            </Panel.Body>
        </Panel>
    );

    return isCollapsed ? compareCollapsedPlaceholder : compareCarouselPlaceholder;
}
export default CompareDrawerPlaceholder;
