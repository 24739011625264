import { connect } from 'react-redux';

import { useFeatures } from 'reaxl-features';

import currentPageNameDuck from '@/ducks/currentPageNameDuck';
import globalConfigsDuck from '@/ducks/globalConfigsDuck';
import { srpAdsDuck } from '@/ducks/srp';
import { vdpAdsDuck } from '@/ducks/vdp';

function DisableAd({
    adsEnabled,
    adUnit,
    page,
    children,
}) {
    const disablePageAdsKey = `DISABLE_${page.toUpperCase()}_ADS`;
    const {
        // Site Brand configs
        ads: [isAdsFeatureEnabled],
        // From Launch Control
        [disablePageAdsKey]: [disablePageAds],
    } = useFeatures(['ads', disablePageAdsKey]);

    const disableAd = !isAdsFeatureEnabled || !adsEnabled || disablePageAds || adUnit === '';

    if (disableAd) {
        return null;
    }
    return children;
}

function mapStateToProps(state) {

    const { adsEnabled } = globalConfigsDuck.selectors.getDuckState(state);
    const page = currentPageNameDuck.selectors.getDuckState(state) || '';

    // TODO: Currently this is only used on the SRP and VDP
    // we should investigate a way to get the adUnit from the DFPContext but it doesn't seem to get populated correctly
    const adsDuck = page === 'srp' ? srpAdsDuck : vdpAdsDuck;
    const { adUnit = '' } = adsDuck.selectors.getDuckState(state);

    return {
        adsEnabled: !!adsEnabled,
        adUnit,
        page,
    };
}

export default connect(mapStateToProps)(DisableAd);
