import url from 'url';

const CacheKey = {

    add(inputUrl, addCacheKill) {
        const parsedUrl = url.parse(inputUrl, false, true);

        if (process.env.NODE_ENV !== 'production') {
            // In DEV mode grab the development (non-minified) bundle.
            const matches = parsedUrl.pathname.match(/^\/resources\/webpack\/(.*)\.min\.(js|css)$/);
            if (matches) {
                parsedUrl.pathname = `/resources/webpack/${matches[1]}.${matches[2]}`;
            }
        }

        if (!parsedUrl.host) {
            parsedUrl.pathname = '/' + process.env.STATIC_CONTENT_CACHE_KEY + parsedUrl.pathname;
        }

        if (addCacheKill) {
            parsedUrl.search = (parsedUrl.search ? parsedUrl.search + '&' : '?')
                + '_=' + (new Date().getTime());
        }

        return url.format(parsedUrl);
    },
};

export default CacheKey;
