import React, { memo, useEffect, useState } from 'react';

import { BrowserStorage } from 'atc-js';

import { Alert, Button, ColoredBackground, Glyphicon, Text } from 'reaxl';
import { sendClick, sendImpressions } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

const browserNotificationPromptCache = new BrowserStorage('OFFSITE_BROWSER_NOTIFS_PROMPT', {
    persist: true,
});
const recentInventoryCache = new BrowserStorage('ATC_RECENT_INVENTORY', {
    persist: true,
});

const BrowserNotificationContainer = memo(({ location = 'srp' }) => {

    const {
        offsite_browser_notifications: [, { delay_srp_prompt: delaySrpPrompt, vdp_prompt_all: vdpPromptAll, vdp_prompt_2_views: vdpPrompt2Views }],
    } = useFeatures([
        'offsite_browser_notifications',
    ]);

    const promptKey = `${location}Prompted`;

    const browserNotifsPrompted = browserNotificationPromptCache.getItem()?.[promptKey];

    const [showPrompt, setShowPrompt] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const recentlyViewedVdps = recentInventoryCache.getItem() || [];

    const oneDay = 24 * 60 * 60 * 1000;
    const today = Date.now();

    const secondRecentTimestamp = recentlyViewedVdps[1]?.timestamp ?? 0;
    const hasViewedTwoVdps = recentlyViewedVdps.length >= 2 && ((today - secondRecentTimestamp) <= oneDay);

    const dismissPrompt = () => {
        setShowPrompt(false);
        browserNotificationPromptCache.setItem({ [promptKey]: value });
    };

    const onButtonClick = () => {
        setShowAlert(true);
        dismissPrompt();
        sendClick('offsiteBrowserPushNotificationClick', {}, { location });
    };

    const sendNotificationImpression = () => sendImpressions({ name: 'offsiteBrowserPushNotificationImpression', data: { location } });

    useEffect(() => {
        if (!browserNotifsPrompted) {
            // SRP
            if (location === 'srp') {
                if ((delaySrpPrompt && hasViewedTwoVdps) || !delaySrpPrompt) {
                    setShowPrompt(true);
                    sendNotificationImpression();
                }
            }

            // VDP
            if (location === 'vdp') {
                if (vdpPromptAll || (vdpPrompt2Views && hasViewedTwoVdps)) {
                    setShowPrompt(true);
                    sendNotificationImpression();
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delaySrpPrompt, hasViewedTwoVdps, vdpPrompt2Views, vdpPromptAll, browserNotifsPrompted, sendImpressions]);

    return (
        <>
            {(showPrompt) && (

                <ColoredBackground
                    className="padding-4 margin-bottom-3 padding-vertical-sm-5 text-size-300 display-flex justify-content-around align-items-center"
                    data-cmp="browserNotification"
                    invertText
                    uiContext="dark-gray"
                >
                    <div className="col-xs-11 display-flex align-items-center ">

                        <Glyphicon
                            glyph="bell"
                            className="text-size-500 margin-right-3"
                        />

                        <span className="margin-right-3 ">
                            Turn on device notifications and stay updated on the cars you want.
                        </span>
                        <Button
                            bsStyle="text"
                            className="text-bold text-blue-light text-right text-sm-left"
                            onClick={onButtonClick}
                            style={{ minWidth: '20%' }}
                        >
                            TURN ON
                        </Button>

                    </div>
                    <div className="col-xs-1 display-flex justify-content-end">
                        <Glyphicon
                            className="text-size-500 text-gray-muted margin-left-3 text-link"
                            glyph="remove"
                            onClick={dismissPrompt}
                        />
                    </div>
                </ColoredBackground>

            )}

            {showAlert && (
                <Alert bsStyle="success">
                    <Text
                        size={300}
                        weight="bold"
                    >
                        Thank you for your interest!
                    </Text>
                    {' '}
                    We look forward to bringing this feature to you.

                </Alert>
            )}

        </>
    );
});

export default BrowserNotificationContainer;
